* {
    box-sizing: border-box;

    user-select: none;
}

html,
body {
    // overflow-x: hidden;
    scroll-behavior: smooth;
}

html {
    @include largeDesktop {
        font-size: 1rem;
    }
}

body {
    font-family: $main-font, sans-serif;
    font-size: $font-size_default;
    background-color: #EAF0FA;
    color: $color-text;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
dl,
li,
a,
span,
pre {
    font-family: $main-font, sans-serif;
}

// h1 {
//     font-size: 52px;
//     font-weight: bold;

//     letter-spacing: -.72px;
// }

// h2 {
//     font-size: 20px;
//     font-weight: bold;
//     line-height: normal;
// }

@include desktop {
    .container-fluid {
        max-width: 90%;
    }
}

a {
    transition: color .3s ease-in-out;

    &,
    &:hover {
        text-decoration: none;
    }
}

ul {
    margin: 0;
    padding: 0;

    list-style: none;
}

button {
    cursor: pointer;

    border: 0;
    outline: 0;
    background: transparent;

    &:disabled {
        cursor: default;
    }
}

.button,
.btn {
    @include button;

    span {
        margin-right: 3px;

        opacity: .5;
    }

    &:focus {
        box-shadow: none !important;
    }
}

.btn-rounded {
    @include buttonRounded;
}

// .btn-primary {
//     color: #fff;
//     background-color: orange;
//     border-color: orange;
// }
.btn-primary {
    background-color: orange;

    &:hover {
        background-color: darken(orange, 10);
    }
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: orange;
    border-color: orange;
}

.btn-outline-primary {
    color: orange;
    border: 1px solid orange;
    border-radius: 10px;
}

.btn-gradient {
    background: transparent linear-gradient(90deg, #F6B330 0%, #FC6B10 100%) 0% 0% no-repeat padding-box;
}

body.theme--dark {
    // .btn-outline-secondary {
    //     color: var(--themeColorGray);
    //     border: 1px solid var(--themeColorGray);
    //     border-radius: 10px;

    //     &:hover {
    //         border-color: #6c757d;
    //     }
    // }
}


.btn-danger {
    color: #fff;
    border: 1px solid #e73737;
    background: #e73737;
    box-shadow: none;

    &:hover {
        color: #e73737;
        border: 1px solid #e73737;
        background: transparent;
    }
}

.btn-link {
    color: orange;

    &:hover {
        text-decoration: none;
    }
}

.button-shadow {
    -webkit-box-shadow: 0px 10px 26px -5px rgba(50, 48, 50, 0.4);
    -moz-box-shadow: 0px 10px 26px -5px rgba(50, 48, 50, 0.4);
    box-shadow: 0px 10px 26px -5px rgba(50, 48, 50, 0.4);

    &:focus {
        -webkit-box-shadow: 0px 10px 26px -5px rgba(50, 48, 50, 0.4) !important;
        -moz-box-shadow: 0px 10px 26px -5px rgba(50, 48, 50, 0.4) !important;
        box-shadow: 0px 10px 26px -5px rgba(50, 48, 50, 0.4) !important;
    }
}

// .container {
//     padding: $space * 2;
// }

.emoji-mart-preview {
    display: none !important;
}

.slick-slider,
.slick-slide {
    outline: none !important;
}

.breadcrumb {
    background: #fff;
    font-size: 1.25rem;
    text-transform: capitalize;
    margin-bottom: 0;
}

.loader {
    min-height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}