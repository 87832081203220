@media screen and (min-width: 992px) {

    .navbar {
        padding: 20px 0;
    }
    .navbar-collapse {
        align-items: flex-end;
    }
    .navbar-expand-lg .navbar-collapse {
        flex-direction: column;
    }
    .navbar-light .navbar-nav .nav-link {
        color: #fff;

        &:hover {
            color: #fff;
        }
    }

    // .nav-link {
    //     box-sizing: border-box;
    //     &:hover::after {
    //         display:block;
    //         content: '';
    //         border-bottom: solid 3px #019fb6;  
    //         transform: scaleX(1);  
    //         transition: transform 250ms ease-in-out;
    //     }
    // }
}