// Spacing
$space: 1.5rem;

$desktop-width: 1024px;

$large-desktop-width: 1600px;

// Colors
$color-bg-menu: #f6f7fb;

$color-text: #545566;

$color-primary: #F6B330;

$color-darkblue: #00215f;

// Fonts
// $main-font: "SignPainter-HouseScript Semibold", sans-serif;
$main-font: 'Nunito Sans', sans-serif;


// Font size
$font-size_default: 1rem;

$font-size-button: 1rem;
