@import '../../../styles/base/variables';
@import '../../../styles/base/mixins';
@import '../../../styles/base/fonts';

.wrapper {
   

    :global(.btn) {
        border: none;
    }

    .card {
        box-shadow: 0px 3px 6px #00000029;
        margin-bottom: 1.875rem;
        border-radius: 10px;
        .cardImg {
            width: 100%;
            height: 15vw;
            object-fit: cover;
            border-top-left-radius: calc(10px - 1px);
            border-top-right-radius: calc(10px - 1px);
        }
    }
}

.cardBody {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E1EAF8;
    opacity: 1;
    padding: 1rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .bookNow {
        text-align: center;
        font-size: .75rem;
        font-weight: 400;
        letter-spacing: 0.51px;
        color: #FFFFFF;
        opacity: 1;
    }
}
.name {
    text-align: left;
    font-weight: normal;
    font-size: 1rem;
    letter-spacing: 0px;
    color: #062746;
    opacity: 1;
}

.orgName {
    text-align: left;
    font-weight: normal;
    font-size: .875rem;
    letter-spacing: 0px;
    color: #062746;
    opacity: 1;
    margin: 5px 0;
}

.price {
    text-align: left;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0px;
    color: #FD9F47;
    opacity: 1;
}
.date {
    text-align: left;
    font-size: .825rem;
    font-weight: 400;
    letter-spacing: 0px;
    color: #062746;
    opacity: 0.8;
    padding: 5px 0;
}
.heading {
    text-align: left;
    font-weight: 400;
    font-size: 2rem;
    letter-spacing: 0px;
    color: #062746;
    opacity: 1;
    margin-bottom: 1.825rem;
}