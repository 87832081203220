@import '../../../styles/base/variables';

.link, .link:hover {
    color: $color-primary;
}
.forgotLink {
    color: #F64712;

    &:hover {
        color: #F64712;
    }
}
.eyeIcon {
    position: absolute;
    right: 10px;
    top: 9px;
    z-index: 4;
}
$size: 45px;

