@import '../../../../styles/base/variables';
@import '../../../../styles/base/mixins';

.sectionHeading {
    text-align: center;
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: 0px;
    color: #F6B330;
    opacity: 1;
    padding: 1.25rem 0;
}

.list {
    li {
        padding: 1rem 0;
        font-size: 1.25rem;

        i {
            padding-right: 1.875rem;
        }
    }
}