@import '../../styles/base/variables';
@import '../../styles/base/mixins';
@import '../../styles/base/fonts';

.wrapper {
    position: relative;
    min-height: 100vh;
    background-image: url('../../assets/bgImage/Group\ 6168_1.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #EAF0FA;
    padding: 2.5rem 0rem;

    :global(.btn) {
        border: none;
    }

    .card {
        box-shadow: 0px 3px 6px #00000029;
        margin-bottom: 1.875rem;
        border-radius: 10px;

        .cardImg {
            width: 100%;
            height: 15vw;
            object-fit: fill;
            border-top-left-radius: calc(10px - 1px);
            border-top-right-radius: calc(10px - 1px);
        }
    }

    .cardBody {
        min-height: 500px;
    }
}

.section {
    padding: 2rem 0;
}


.cardHeader {}

.cardBody {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E1EAF8;
    opacity: 1;
    padding: 1rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .bookNow {
        text-align: center;
        font-size: .75rem;
        font-weight: 400;
        letter-spacing: 0.51px;
        color: #FFFFFF;
        opacity: 1;
    }
}

.name {
    text-align: left;
    font-weight: normal;
    font-size: 1rem;
    letter-spacing: 0px;
    color: #062746;
    opacity: 1;
}

.price {
    text-align: left;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0px;
    color: #FD9F47;
    opacity: 1;
}

.date {
    text-align: left;
    font-size: .825rem;
    font-weight: 400;
    letter-spacing: 0px;
    color: #062746;
    opacity: 0.8;
    padding: 5px 0;
}

.heading {
    text-align: left;
    font-weight: 400;
    font-size: 2rem;
    letter-spacing: 0px;
    color: #062746;
    opacity: 1;
    margin-bottom: 1.825rem;
}

.imageBox {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    height: 100px;
}

.brandName {
    font-size: 42px;
    font-family: "SignPainter-HouseScript Semibold";
    padding-left: 15px;
}

.modalBody {
    padding: 2rem;

    .bookNow {
        background: #062746 0% 0% no-repeat padding-box;
        opacity: 1;
        padding: 10px 30px;
        color: #fff;
        display: flex;
        margin: auto;

        &:hover,
        &:focus {
            background: #062746 0% 0% no-repeat padding-box;
            outline: none;
        }
    }
}

.modalCard {
    padding: 1rem;
}

.closeIcon {
    width: 20px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}

.content {
    padding: 50px 0;
}

.drawName {
    border-bottom: 1px dashed #062746;
    opacity: 1;
    padding-bottom: 10px;
    font-size: 1.5rem;
}

.description {
    text-align: left;
    font-size: 0.75rem;
    letter-spacing: 0px;
    color: #545454;
    opacity: 1;
    padding: 20px 0;
}

.details {
    text-align: left;
    font-size: 0.875rem;
    letter-spacing: 0px;
    color: #545454;
    opacity: 1;

    li i,
    li svg {
        margin-right: 10px;
        color: #F6B330;
    }
}

.succesImg {
    width: 250px;
    display: flex;
    margin: auto;
}

.successText {
    text-align: center;
    font-size: 2rem;
    letter-spacing: 0px;
    color: #F6B330;
    opacity: 1;
    padding: 30px 0;
}

.noData {
    font-size: 1.5rem;
    color: #062746;
    text-align: center;
}