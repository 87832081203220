@import '../../styles/base/variables';
@import '../../styles/base/mixins';
@import '../../styles/base/fonts';

.wrapper {
    position: relative;
    min-height: 100vh;
    background-image: url('../../assets/bgImage/Group\ 6168_1.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #EAF0FA;
    padding: 2.5rem 0rem;

    :global(.btn) {
        border: none;
    }

    .cardBody {
        min-height: 500px;
    }
}

.section {
    padding: 2rem 0;
}


.drawName {
    font-size: .725rem;
    letter-spacing: 0.9px;
    color: #FC6B10;
    opacity: 1;
}

.noData {
    font-size: 1.5rem;
    color: #062746;
    text-align: center;
}
.cursor {
    cursor: pointer;
}
.modalBody {
    padding: 2rem;

    .bookNow {
        background: #062746 0% 0% no-repeat padding-box;
        opacity: 1;
        padding: 10px 30px;
        color: #fff;
        display: flex;
        margin: auto;

        &:hover,
        &:focus {
            background: #062746 0% 0% no-repeat padding-box;
            outline: none;
        }
    }
}

.modalCard {
    padding: 1rem;
}

.closeIcon {
    width: 20px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}
.imageBox {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    height: 100px;
}

.brandName {
    font-size: 42px;
    font-family: "SignPainter-HouseScript Semibold";
    padding-left: 15px;
}