@import '../../../../styles/base/variables';
@import '../../../../styles/base/mixins';

.section {
    padding: 4rem 0;

    .sectionHeading {
        text-align: center;
        font-size: 2rem;
        font-weight: 400;
        letter-spacing: 0px;
        color: #F6B330;
        opacity: 1;
        padding: 1.25rem 0;
        margin-bottom: 3rem;
    }
}

.card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #0627460D;
    border: 1px solid #E1EAF8;
    border-radius: 10px;
    opacity: 1;
    margin-bottom: 3rem;
    height: 80%;
    height: calc(100% - 50px);

    .cardBody {
        padding-top: 3rem;
    }
}


.cardImg {
    width: 62px;
    margin: auto;
    position: absolute;
    top: -32px;
    left: 0;
    background: #fff;
    right: 0;
    border-radius: 50px;
    padding: 3px;
    /* outline: 1px solid #fff; */
    border: 4px solid #E1EAF8;
}

.title {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: 0px;
    color: #F6B330;
    opacity: 1;
    text-transform: capitalize;
}

.description {
    text-align: center;
    font-size: 1rem;
    font-weight: normal;
    letter-spacing: 0px;
    color: #062746;
    opacity: 1;
    line-height: 1.8;
}