 
    .logo {
       height: 100px;
       object-fit: cover;
    }
    .brandName {
        font-size: 42px;
        font-family: "SignPainter-HouseScript Semibold";
        padding-left: 15px;
    }
    .navLink {
        padding-left: 1rem!important;
        padding-right: 1rem!important;
        text-align: center;
        font-size: .725rem;
        font-weight: 600;
        letter-spacing: 0.72px;
        color: #062746!important;
        opacity: 1;
        &:hover{
            color: #062746!important;
        }
    }

