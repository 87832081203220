.pagination {
    float: right;

    .page-item {
        margin: 0 5px;

        @include mobile {
            margin: 0 2px;
        }
    }

    .page-link {
        color: #151D4A;
    }

    .page-item.active .page-link {
        color: #EE243E;
        border: 1px solid #EE243E;
        z-index: 1;
    }

    .page-item .page-link {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #E6E6E6;
        border-radius: 4px;
        padding: 4px 8px;
        opacity: 1;
    }

    .page-item.first .page-link,
    .page-item.last .page-link,
    .page-item.prev .page-link,
    .page-item.next .page-link {
        border: 1px solid #E6E6E6;
        border-radius: 4px;
    }

    .page-item:first-child .page-link {
        margin-left: 0;
        // border-top-left-radius: 0;
        // border-bottom-left-radius: 0;
    }

    .page-item:last-child .page-link {
        // border-top-right-radius: 0;
        // border-bottom-right-radius: 0;
    }
    .page-link:focus {
        box-shadow: none;
    }
}