/*******************************************************************************
*** FONTS
*******************************************************************************/
@font-face {
    font-family: "SignPainter-HouseScript Semibold";
    // font-weight: 800;
    font-style: normal;

    src: url('../../assets/fonts/SignPainter-HouseScript/signpainter-semibold.woff2') format('woff2'),
    url('../../assets/fonts/SignPainter-HouseScript/signpainter-semibold.woff') format('woff');
}

@font-face {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 500;
    font-style: normal;

    src: url('../../assets/fonts/Nunito/Nunito-Black.ttf') format('ttf');
}

@font-face {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 300;
    font-style: normal;

    src: url('../../assets/fonts/Nunito/Nunito-Regular.ttf') format('ttf');
}

@font-face {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: bold;
    font-style: normal;

    src: url('../../assets/fonts/Nunito/Nunito-SemiBold.ttf') format('ttf');
}

@font-face {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 900;
    font-style: normal;

    src: url('../../assets/fonts/Nunito/Nunito-Bold.ttf') format('ttf');
}
@font-face {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 300;
    font-style: normal;

    src: url('../../assets/fonts/Nunito/Nunito-Light.ttf') format('ttf');
}

// @font-face {
//     font-family: 'AirbnbCereal';
//     font-weight: normal;
//     font-style: normal;

//     src: url('../../assets/fonts/AirbnbCereal-Book.woff2') format('woff2'),
//     url('../../assets/fonts/AirbnbCereal-Book.woff') format('woff');
// }

.Nunito-Light {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 300;
}
.Nunito-Regular {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
}
.Nunito-SemiBold {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
}
.Nunito-Bold {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 700;
}