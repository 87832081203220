@import '../../styles/base/variables';
@import '../../styles/base/mixins';
@import '../../styles/base/fonts';

.content {
    .formGroup {
        position: relative;
        display: flex;
        flex-flow: column;
        margin-bottom: 20px;
    }

    .formControl {
        display: block;
        width: 100%;
        /* height: calc(1.5em + 0.75rem + 2px); */
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        /* background-clip: padding-box; */
        /* border: 1px solid #ced4da; */
        /* border-radius: 0.25rem; */
        /* transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */
        padding-left: 0;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #151D4A4D;
    }
}
.userName {
    font-size: 1.2rem;
    letter-spacing: 0px;
    color: #333132;
    font-weight: normal;
    opacity: 1;
    text-align: center;
    padding: 20px 0;
}