@import '../../../../styles/base/variables';
@import '../../../../styles/base/mixins';
@import '../../../../styles/base/fonts';

.paginationWrapper {

    display: flex;
    justify-content: space-between;

    @include mobile {
        flex-direction: column;
    }

    .verticleDivider {
        width: 2px;
        height: 22px;
        border: 1px solid #151D4A33;
        opacity: 1;
    }
    .text {
        
        font-size: .725rem;
        letter-spacing: 0px;
        color: #151D4A;
        opacity: 1;
    }

}
