@import '../../../styles/base/variables';

.timer {
    text-align: center;
    font-size: 1.25rem;
    font-weight: bold;
    color: $color-primary;
    padding: 1.5rem 0;
}
.number {
    text-align: center;
    font-size: 1.2rem;
    letter-spacing: 3.5px;
    color: #333132;
    opacity: 1;
    padding: 20px 0;
}
.resend {
    text-align: center;
    font-size: 1.2rem;
    letter-spacing: 0px;
    color: #062746;
    opacity: 1;
}
.resendButton {
    background: none;
    border: none;
    color: orange;
    padding: 6px 0;
    font-weight: 400;
    font-size: 1.2rem;


    &:focus, &:hover {
        background: none;
        border: none;
        outline: none;
        color: orange;
    }
}