
@import '../../../styles/base/variables';
@import '../../../styles/base/mixins';
@import '../../../styles/base/fonts';
.wrapper {
    .filterBox {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-flow: row;

        & > div:not(:first-child) {
            margin-left: 2rem;

            @include mobile {
                margin-left: 0;
            }
        }

        :global(.form-check-label) {
            text-align: left;
            font-size: .725rem;
            letter-spacing: 0.85px;
            color: #062746;
            text-transform: uppercase;
            opacity: 1;
        }

        @include mobile {
            justify-content: flex-start;
            flex-flow: column;
            align-items: flex-start;
        }
    }

    .search {
        width: 240px;
        height: 36px;
        background: #FFFFFF;
        border: 1px solid #E1EAF8;
        border-radius: 18px;
        opacity: 1;
        text-align: left;
        font-size: .825rem;
        letter-spacing: 0.85px;
        color: #151D4A99;
        opacity: 1;
        padding-right: 25px;
    }
    .searchIcon {
        position: absolute;
        right: 10px;
        top: 12px;
    }

}