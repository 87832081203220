.headerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    background: transparent;
    padding: 20 0px;

    .overlayBrand {
        z-index: 3;

        .brandName {
            color: #FFFFFF;
        }
    }

    .logo {
        height: 100px;
        object-fit: cover;
    }

    .brandName {
        font-size: 42px;
        font-family: "SignPainter-HouseScript Semibold";
        padding-left: 15px;
    }

    .headerLeft,
    .headerRight {
        display: flex;
        align-items: center;
    }

    .searchBar {
        width: 325px;
        border-bottom: none;
        background: rgba(255, 255, 255, .4);
        border-radius: 2rem;
        height: 35px;
        padding-left: 35px;

        &:focus,
        &:active {
            background: rgba(255, 255, 255, .4);
        }
    }

    .dropdown-menu {
        left: unset;
        right: 0;
    }

    .navLink {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }


    .link-border {
        padding: 5px;
        border: 1px solid;
        border-radius: 20px;
    }


    .loginLink {
        text-align: right;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 0px;
        padding: 2px 12px;
        color: #062746 !important;
        text-transform: capitalize;
        opacity: 1;


        & a {
            @extend .loginLink;

        }
    }

    .bookNow {
        text-align: center;
        font-weight: 600;
        letter-spacing: 0.72px;
        color: #FFFFFF;
        opacity: 1;

        &:hover {
            color: #fff !important;
        }
    }

    .userDropdown {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;

        .name {
            text-align: right;
            font-size: 1rem;
            font-weight: 600;
            letter-spacing: 0px;
            color: #062746;
            text-transform: capitalize;
            opacity: 1;
        }

        img {
            width: 40px;
            height: 40px;
            border-radius: 50px;
            object-fit: conver;
        }
    }

    .searchIcon {
        position: absolute;
        left: 10px;
        top: 10px;
        /* padding: 5px; */
        width: 16px;
    }

    .showSearch {
        &::before {
            content: '';
            width: 100vw;
            height: 100%;
            position: fixed;
            background: #062746;
            opacity: 0.5;
            left: 0;
            top: 0;
            z-index: 2;
        }

        .searchBar {
            position: sticky;
            background: #fff;
            z-index: 3;
            color: #062746;

            &:focus,
            &:active {
                background: #fff;
            }
        }

        .searchIcon {
            color: #062746;
            fill: #062746;
            z-index: 3;
        }
    }

    .searchContent {
        position: absolute;
        top: 20px;
        width: 100%;
        left: 0;
        z-index: 2;
        color: #062746;
    }

    .closeIcon {
        position: absolute;
        width: 15px;
        right: 8px;
        top: 10px;
        z-index: 4;
        cursor: pointer;
    }
}

.dropdown {

    :global(.dropdown-item) {
        padding: 10px;
    }

    i {
        color: #F6B330;
    }
}

.groupItem {
    cursor: pointer;
}