/*******************************************************************************
*** FORMS
*******************************************************************************/
@import './variables';
input {
    outline: none;
}
input:-internal-autofill-selected {
    background-color: #fff!important;
}
textarea {
    resize: none;
    transition: border-color .3s ease-in-out;

    outline: none;
}

input, textarea {
    -webkit-user-select: text;
       -moz-user-select: text;
        -ms-user-select: text;
            user-select: text;
}

form {
    input, textarea, select {
    &.form-control, &.custom-select {
        font-size: .825rem;
        font-weight: 400;

        color: $color-text;
        color: var(--themeColorPrimary);
        border-color: $color-text;
        border-color: var(--themeColorPrimary);
        border-radius: 0px;
        background: transparent;
        border: none;
        border-bottom: 1px solid #ddd;
        height: 2.5rem;
        &:focus {
            // color: $color-blue;
            // border-color: $color-blue;
            // background: transparent;
            // box-shadow: none;
            color: #495057;
            background-color: #fff;
            border-color: #ced4da;
            outline: 0;
            box-shadow: none;
            & + label {
                color: $color-primary;
            }
        }

        &:disabled {
            border-color: rgba($color-text, .2);
            background: transparent;
        }

        &.invalid {
            border-color: #f00 !important;

            & + label {
                color: #f00 !important;
            }
        }
    }

    .input-group-prepend + :not(.form-control) {
        padding-left: 0;
    }
    &.form-check-input {
        position: relative;

                appearance: none;
        -webkit-appearance: none;

        &::before {
            position: absolute;

            display: block;

            content: '';
        }

        &[type=checkbox] {
            width: 23px;
            height: 23px;

            &::before {
                content: '';
                width: 20px;
                height: 20px;

                border: none;
                border-radius: 5px;

                background: url('../../assets/images/checkbox.svg') 0 0 / 20px no-repeat;
            }

            &:checked {
                background: url('../../assets/images/check-square.svg') 0 0 / 20px no-repeat;

                &::before {
                    opacity: 0;
                }
            }
        }

        &[type=radio] {
            width: 15px;
            height: 15px;

            &::before {
                top: 1px;
                left: 1px;

                width: 12px;
                height: 12px;

                border: 1px solid #acadbb;
                border-radius: 100%;
            }

            &:checked {
                background: url(../../assets/images/checked.svg) -1px -1px / 16px no-repeat;

                &::before {
                    opacity: 0;
                }
            }
        }
    }
}
}

.form-check {
    display: flex;
    align-items: center;
}
input, textarea, select {
    &.form-control , &.custom-select{
        font-size: 1rem;
        font-weight: 300;
        padding: .375rem 0.75rem;
        color: $color-text;
        color: var(--themeColorPrimary);
        border-color: $color-text;
        border-color: var(--themeColorPrimary);
        border-radius: 0px;
        background: #fff;
        border: none;
        height: 2.5rem;
        // border-bottom: 1px solid #ddd;
        &:focus {
            // color: $color-blue;
            // border-color: $color-blue;
            // background: transparent;
            // box-shadow: none;
            color: #495057;
            background-color: #fff;
            border-color: #ced4da;
            outline: 0;
            box-shadow: none;
            & + label {
                color: $color-primary;
            }
        }

        &:disabled {
            border-color: rgba($color-text, .2);
            background: transparent;
        }

        &.invalid {
            border-color: #f00 !important;

            & + label {
                color: #f00 !important;
            }
        }
    }

    &.form-check-input {
        position: relative;

                appearance: none;
        -webkit-appearance: none;

        &::before {
            position: absolute;

            display: block;

            content: '';

        }

        &[type=checkbox] {
            width: 23px;
            height: 23px;

            &::before {
                content: '';
                width: 20px;
                height: 20px;

                // border: 1px solid #545566;
                border-radius: 5px;
                background: url(../../assets/images/checkbox.svg) 0 0 / 20px no-repeat;
            }

            &:checked {
                background: url(../../assets/images/checked.svg) 0 0 / 20px no-repeat;

                &::before {
                    opacity: 0;
                }
            }
        }

        &[type=radio] {
            width: 15px;
            height: 15px;

            &::before {
                top: 1px;
                left: 1px;

                width: 12px;
                height: 12px;

                border: 1px solid #acadbb;
                border-radius: 100%;
            }

            // &:checked {
            //     background: url(../../assets/images/icons/checked.svg) -1px -1px / 16px no-repeat;

            //     &::before {
            //         opacity: 0;
            //     }
            // }
        }
    }
}
.form-check {
    .form-check-label {
        padding-left: 5px;
    }
}

label.form-label {
    font-size: 14px;
    font-weight: 300;

    margin: 0;

    opacity: .7;

@include desktop {
        font-size: 11px;
        font-weight: normal;
    }
}

.form-group {
    position: relative;

    display: flex;
    flex-flow: column;

    margin-bottom: 15px;
}

.input-group-prepend {
    // position: absolute;
    // left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    // width: 40px;
    // height: 40px;

    color: #fff;
    border: 0;
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid #ddd;
    // & + input.form-control {
    //     padding-left: calc(.75rem + 40px);
    // }

    & .input-group-text {
        padding: 0.375rem 0.25rem;
        background-color: #fff;
        border: none;
        border-radius: 0;
    }
}
.invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0;
    font-size: small;
    color: #dc3545;
}
.react-tel-input .form-control {
    width: 100%;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #dddddd;
    padding-left: 48px!important;
}
.react-tel-input .flag-dropdown {
    border-radius: 0;
    background: transparent;
    border: none;
}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
    border-bottom: 1px solid #dddddd;
}
.reg-form {
    .form-control {
        padding: .375rem 0rem;
    }
}