@import '~bootstrap/scss/bootstrap';
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css");
@import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
@import './base/normalize';

// @import './base/fonts';

@import './base/variables';

@import './base/mixins';

@import './base/base';
@import './base/card';
@import './base/forms';
@import './base/navbar';
@import "./components/pagination";
@import "./components/verificationNumber";
@import "./components/tables";
