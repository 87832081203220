@import '../../../../styles/base/variables';
@import '../../../../styles/base/mixins';

.sectionHeading {
    text-align: center;
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: 0px;
    color: #F6B330;
    opacity: 1;
    padding: 1.25rem 0;
}
.priceContent {
    background: #000;
    color: #fff;
    border-radius: 5px;
    padding: 20px;
    transform: scale(1.5);

    @include mobile {
        transform: scale(1);
    }
}
.price {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-bottom: 20px;
}
.title {
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-bottom: 20px;
}
.text {
    font-size: .725rem;
    font-weight: normal;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    padding-bottom: 10px;
    line-height: 1.4;
}
.buttonBox {
    display: flex;
    padding: 1rem 0;
    width: 40%;
    justify-content: space-between;
    margin: auto;
    @media only screen and (max-width: 600px) {
        width: unset;
      }

    button {
        text-align: center;
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 1.02px;
        opacity: 1;
    }
}
.readyText {
    text-align: center;
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 0px;
    color: #062746;
    opacity: 1;
}