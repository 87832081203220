.styles_react-code-input-container__tpiKG {
    width: 100%!important;

    .styles_react-code-input__CRulA{
        display: flex;
        justify-content: space-around;
    }
    .styles_react-code-input__CRulA > input:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .styles_react-code-input__CRulA > input:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .styles_react-code-input__CRulA > input {
        margin: 0 5px; 
        border:solid 1px #a8adb7;
        border-radius: 4px;
    }

    .styles_react-code-input__CRulA > input:focus + input {
        border-left:solid 1px #a8adb7;
    }
}
