@import '../../styles/base/variables';
@import '../../styles/base/mixins';
@import '../../styles/base/fonts';

.wrapper {
    position: relative;
    min-height: 100vh;
    background-image: url('../../assets/bgImage/Group\ 6168_1.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #EAF0FA;
    padding: 2.5rem 0rem;

    :global(.btn) {
        border: none;
    }

    .card {
        box-shadow: 0px 3px 6px #00000029;
        margin-bottom: 1.875rem;
        border-radius: 10px;
        .cardImg {
            width: 100%;
            height: 15vw;
            object-fit: fill;
            border-top-left-radius: calc(10px - 1px);
            border-top-right-radius: calc(10px - 1px);
        }
    }
}
.section {
    padding: 2rem 0;
}


.cardHeader {

}
.cardBody {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E1EAF8;
    opacity: 1;
    padding: 1rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.heading {
    margin-bottom: 1.825rem;

    text-align: center;
    font-size: 1.85rem;
    font-weight: 400;
    letter-spacing: 0px;
    color: #FC6B10;
    opacity: 1;
}
.aboutImage {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    img {
        width: 300px;
    }
}
.head {
    text-align: left;
    font-size: 1.25rem;
    font-weight: bold;
    letter-spacing: 0px;
    color: #062746;
    opacity: 1;
}
.description {
    text-align: left;
    font-size: 1.25rem;
    font-weight: 400;
    letter-spacing: 0px;
    color: #062746;
    opacity: 1;  
}
.head, .description {
    margin-bottom: 20px;
}