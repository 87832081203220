.table {
    font-size: .725rem;
    letter-spacing: 0.85px;
    color: #151D4A99;
    opacity: 1;
    border: none;

    & thead th {
        border-bottom: none;
    }
    & th, & td {
        border-top: none;
    }
    .table-bordered thead th, .table-bordered thead td {
        border-bottom-width: 1px;
    }
    th {
        border: none;
    }
}
.table-bordered th, .table-bordered td {
    border-bottom: 1px solid #dee2e6;
}
.table-bordered th, .table-bordered td {
    border: none;
    border-bottom: 1px solid #dee2e6;
}
.min-height {
    min-height: 420px;
}