@import '../../../../styles/base/variables';
@import '../../../../styles/base/mixins';
@import '../../../../styles/base/fonts';

.section {
    padding: 4rem 0;

    :global(.btn) {
        border: none;
    }
    h1 {
        @extend .Nunito-SemiBold;
        font-size: 3rem;
        margin: 3rem 0;
    }

    p {
        @extend .Nunito-Regular;
        text-align: left;
        font-size: 1.25rem;
        letter-spacing: 0px;
        color: #8193B1;
        opacity: 1;
        line-height: 1.8rem;
        width: 40%;
        margin-bottom: 50px;
    }
    .touchText {
        text-align: left;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 0px;
        color: #062746;
        opacity: 1;
        margin-bottom: 25px;
    }

    .watchButton {
        font-size: .7rem;
        padding: .75rem .75rem;
        font-weight: 400;
        margin-right: 25px;
        @include mobile {
            margin-bottom: 25px;
            margin-right: 0;
        }
    }

    .watchInput {
        display: flex;
        

        .inputBox {
            width: 325px;
            display: flex;
            input {
                border-bottom-left-radius: 4px;
                border-top-left-radius: 4px;
            }
            button {
                border-top-right-radius: 4px!important;
                border-bottom-right-radius: 4px!important;
            }
        }

        @include mobile {
            flex-direction: column;
        }
    }
}